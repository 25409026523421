import * as React from "react";
import Column from "../../ui-library/column-system/column";
import Row from "../../ui-library/column-system/row";
import Section from "../../ui-library/section/section";
import QuestionLink from "../../ui-library/question-link/question-link";
import { EventCategory } from "../../../utils/analytics";
import { useCovidIntentListQuery } from "../../../hooks/static-queries/use-covid-intent-list-query";
import { useCountriesQuery } from "../../../hooks/static-queries/use-countries-query";
import {
  getCountryUrl,
  getCountryWithResUrl,
  getCOVIDIntentUrl,
} from "../../../utils/question-url";
import { useCountriesWithResponseQuery } from "../../../hooks/static-queries/use-countries-with-response-query";

const RecentlyAskedQuestions: React.FC = () => {
  const intents = useCovidIntentListQuery();
  const countries = useCountriesQuery();
  const countriesWithResponse = useCountriesWithResponseQuery();

  if (!intents || intents.length < 4) {
    return null;
  }

  const selectedIndexes: number[] = [];
  const selectedCountryIndexes: number[] = [];
  const selectedCountryWithResIndexes: number[] = [];

  const chooseIntentIndex = (): number => {
    const randomIndex = Math.floor(Math.random() * intents.length);
    if (selectedIndexes.indexOf(randomIndex) === -1) {
      return randomIndex;
    }
    return chooseIntentIndex();
  };
  const chooseCountryIndex = (): number => {
    const randomIndex = Math.floor(Math.random() * countries.length);
    if (selectedCountryIndexes.indexOf(randomIndex) === -1) {
      return randomIndex;
    }
    return chooseIntentIndex();
  };
  const chooseCountryWithResIndex = (): number => {
    const randomIndex = Math.floor(
      Math.random() * countriesWithResponse.length
    );
    if (selectedCountryWithResIndexes.indexOf(randomIndex) === -1) {
      return randomIndex;
    }
    return chooseIntentIndex();
  };

  for (let i = 0; i < 2; i++) {
    const r = chooseIntentIndex();
    selectedIndexes.push(r);
  }
  for (let i = 0; i < 1; i++) {
    const r = chooseCountryIndex();
    selectedCountryIndexes.push(r);
  }
  for (let i = 0; i < 1; i++) {
    const r = chooseCountryWithResIndex();
    selectedCountryWithResIndexes.push(r);
  }

  const chosenIntents = selectedIndexes.map((i) => intents[i]);
  const chosenCountries = selectedCountryIndexes.map((i) => countries[i]);
  const chosenCountriesWithRes = selectedCountryWithResIndexes.map(
    (i) => countriesWithResponse[i]
  );

  return (
    <Section heading="You might like to ask">
      <Row>
        {chosenIntents.map((q) => (
          <Column key={q.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionLink
              to={getCOVIDIntentUrl(q.alias)}
              eventCategory={EventCategory.Section_RandomIntents}
            >
              {q.displayName}
            </QuestionLink>
          </Column>
        ))}
        {chosenCountries.map((c) => (
          <Column key={c.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionLink
              to={getCountryUrl(c.alias)}
              eventCategory={EventCategory.Section_RandomIntents}
            >
              {`What is the COVID-19 situation in ${c.displayName}?`}
            </QuestionLink>
          </Column>
        ))}
        {chosenCountriesWithRes.map((c) => (
          <Column key={c.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionLink
              to={getCountryWithResUrl(c.alias)}
              eventCategory={EventCategory.Section_RandomIntents}
            >
              {`What's the government response timeline for ${c.displayName}?`}
            </QuestionLink>
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default RecentlyAskedQuestions;
