import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo/seo";
import Container from "../components/ui-library/container/container";
import PageContainer from "../components/ui-library/page-container/page-container";
import PageHeading from "../components/ui-library/page-heading/page-heading";
import { LogoBunny } from "../components/site/header/header";
import Navigation from "../components/site/navigation/navigation";
import RecentlyAskedQuestions from "../components/covid-19/recently-asked-questions/recently-asked-questions";

const NotFoundPage: React.FC = () => (
  <Layout headerUrl="/" logoBunny={LogoBunny.Main}>
    <SEO title="404: Not found" />

    <Navigation />

    <PageContainer>
      <PageHeading backUrl="/covid-19/">404: Page not found</PageHeading>

      <Container>
        <RecentlyAskedQuestions />
      </Container>
    </PageContainer>
  </Layout>
);

export default NotFoundPage;
