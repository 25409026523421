import * as React from "react";
import { Interpolation } from "@emotion/core";
import { grid } from "../../design-tokens/grid";
import { Device } from "../../design-tokens/media-queries";

const rootStyle: Interpolation = {
  marginTop: grid(2),
  marginBottom: grid(6),

  [Device.DesktopTablet]: {
    marginTop: grid(3),
  },
};

interface PageContainerProps {
  isNoMarginTop?: boolean;
}

const PageContainer: React.FC<PageContainerProps> = ({
  isNoMarginTop,
  children,
}) => {
  return (
    <div css={[rootStyle, isNoMarginTop && { marginTop: 0 }]}>{children}</div>
  );
};

export default PageContainer;
