import { Interpolation } from "@emotion/core";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Fonts } from "../../design-tokens/fonts";
import { Colors } from "../../design-tokens/colors";
import { grid } from "../../design-tokens/grid";
import { Keyframes } from "../../design-tokens/animation";
import { Border } from "../../design-tokens/borders";
import { BoxShadow } from "../../design-tokens/box-shadow";

const root: Interpolation = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  backgroundColor: Colors.White,
  marginBottom: grid(2.5),
  boxShadow: BoxShadow.Default,
  transition: "all 0.2s ease",
  animation: `${Keyframes.FadeIn} 0.3s linear`,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },

  [Device.Desktop]: {
    borderRadius: Border.Radius,
  },
};

const backUrlContainer: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(14.5),
  height: grid(8),
  flexShrink: 0,

  [Device.Mobile]: {
    display: "none",
  },
};

const backUrl: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  cursor: "pointer",
};

const headingContainer: Interpolation = {
  flex: 1,
  padding: `${grid(3)} ${grid(2)}`,

  [Device.DesktopTablet]: {
    padding: `${grid(3)} ${grid(6)} ${grid(3)} ${grid(2.5)}`,
  },
};

const heading: Interpolation = {
  position: "relative",
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: Fonts.Weight.Bold,

  "&::before": {
    position: "absolute",
    left: grid(-2.5),
    top: 3,
    content: `''`,
    height: `calc(100% - 6px)`,
    width: 5,
    backgroundColor: Colors.Blue,
  },

  [Device.DesktopTablet]: {
    fontSize: "30px",
    lineHeight: "36px",
  },
};

const subHeadingContainer: Interpolation = {
  marginTop: grid(1.5),

  [Device.DesktopTablet]: {
    display: "flex",
    alignItems: "flex-end",
  },
};

const subHeading: Interpolation = {
  fontSize: "18px",
  fontWeight: Fonts.Weight.SemiBold,
  lineHeight: "22px",
  color: Colors.MidGrey,
  fontStyle: "italic",

  [DarkTheme]: {
    color: Colors.DarkModeTextGrey,
  },

  [Device.DesktopTablet]: {
    flex: 1,
  },
};

const detail: Interpolation = {
  fontSize: "12px",
  color: Colors.MidGrey,
  marginTop: grid(0.5),

  [DarkTheme]: {
    color: Colors.DarkModeTextGrey,
  },

  [Device.DesktopTablet]: {
    whiteSpace: "nowrap",
    flexShrink: 0,
    marginLeft: grid(2),
    marginTop: 0,
  },
};

const styles = {
  root,
  backUrlContainer,
  backUrl,
  headingContainer,
  heading,
  subHeadingContainer,
  subHeading,
  detail,
};

export default styles;
