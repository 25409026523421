import * as React from "react";
import { navigate } from "gatsby";
import Image from "../image/image";
import Container from "../container/container";
import {
  EventCategory,
  pushActionableClickEvent,
} from "../../../utils/analytics";
import BackIcon from "./chevron.svg";
import styles from "./page-heading.styles";

interface PageHeadingProps {
  backUrl?: string;
  subHeading?: string;
  detail?: string;
  isNoBack?: boolean;
}

const PageHeading: React.FC<PageHeadingProps> = ({
  backUrl,
  subHeading,
  detail,
  isNoBack,
  children,
}) => {
  return (
    <Container fullWidthOnMobile={true}>
      <div css={styles.root}>
        <div css={styles.backUrlContainer}>
          {!isNoBack && (
            <div
              onClick={() => {
                if (history && history.length > 1) {
                  history.back();
                } else {
                  navigate(backUrl || "/covid-19/");
                }
                pushActionableClickEvent({
                  category: EventCategory.Site_Navigate,
                  action: "Click - Back",
                  label: "Back",
                });
              }}
              css={styles.backUrl}
            >
              <Image src={BackIcon} alt="Back" />
            </div>
          )}
        </div>
        <div css={styles.headingContainer}>
          <h1 css={styles.heading}>{children}</h1>
          {!!subHeading && !!detail && (
            <div css={styles.subHeadingContainer}>
              {!!subHeading && <div css={styles.subHeading}>{subHeading}</div>}
              {!!detail && <div css={styles.detail}>{detail}</div>}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PageHeading;
