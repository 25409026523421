import { NavItem } from "../types/nav-item";
import { getCOVIDIntentUrl } from "../utils/question-url";

export const TOP_NAV_ITEM = {
  SiteHome: {
    label: "Home",
    url: "/",
  },
  CovidHome: {
    label: "Home",
    url: "/covid-19/",
  },
  Insights: {
    label: "Insights",
    url: "/covid-19/insights/",
  },
  Topics: {
    label: "Topics",
    url: getCOVIDIntentUrl("what-covid-19-topics-can-i-explore"),
  },
  Resources: {
    label: "Resources",
    url: getCOVIDIntentUrl("what-are-some-good-covid-19-related-websites"),
  },
};

export const HOME_NAV_ITEMS: NavItem[] = [
  TOP_NAV_ITEM.SiteHome,
  TOP_NAV_ITEM.Insights,
  TOP_NAV_ITEM.Topics,
  TOP_NAV_ITEM.Resources,
];

export const COVID_NAV_ITEMS: NavItem[] = [
  TOP_NAV_ITEM.CovidHome,
  TOP_NAV_ITEM.Insights,
  TOP_NAV_ITEM.Topics,
  TOP_NAV_ITEM.Resources,
];
